<template>
  <header class="header navbar navbar-expand bg-light border-bottom border-light shadow fixed-top">
    <div class="container-fluid pe-lg-4">
      <div class="d-flex align-items-center">
        <router-link to="/" class="navbar-brand flex-shrink-0 py-1 py-lg-2">
          <img src="../assets/img/d1m.png" width="47" alt="Silicon">
        </router-link>
        <!-- <span class="badge bg-success">{{ badgeText }}</span> -->
      </div>
      <div class="d-flex align-items-center w-100">
        <ul class="navbar-nav d-none d-lg-flex" style="padding-left: 11.75rem;">
          <li class="nav-item" v-for="item in navItems" :key="item.label">
            <router-link :to="item.link" class="nav-link">
              <i :class="item.iconClass"></i>
              &nbsp;{{ item.label }}
            </router-link>
          </li>
        </ul>

        <button type="button" class="navbar-toggler d-block d-lg-none ms-auto me-4" data-bs-toggle="offcanvas" data-bs-target="#docsNav" aria-controls="docsNav" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="form-check form-switch mode-switch pe-lg-1 ms-lg-auto" data-bs-toggle="mode">
          <input class="form-check-input" type="checkbox" id="theme-mode" :checked="isDarkMode" @change="toggleTheme">
          <label class="form-check-label d-none d-sm-block d-lg-none d-xl-block" for="theme-mode">{{ themeLabel }}</label>
        </div>


        <a :href="buyLink" class="btn btn-primary btn-sm fs-sm rounded ms-4 d-none d-lg-inline-flex" target="_blank" rel="noopener">
          <i class="bx bx-cart fs-5 lh-1 me-1"></i>
          &nbsp;Expore
        </a>
      </div>
    </div>
  </header>
</template>


<script>
export default {
  data() {
    return {
      logoSrc: "../assets/logo.png",
      brandName: "D1m",
      badgeText: "Docs",
      isDarkMode: false,
      themeLabel: "Light",
      buyLink: "https://themes.getbootstrap.com/product/silicon-business-technology-template-ui-kit/",
      navItems: [
        { label: "Live preview", link: "/index", iconClass: "bx bx-desktop opacity-70 fs-lg me-1" },
        { label: "UI Kit (Components)", link: "/components/typography", iconClass: "bx bx-layer opacity-70 fs-lg me-1" }
      ]
    };
  },
  methods:{
    toggleTheme() {
        this.isDarkMode = !this.isDarkMode;
        if (this.isDarkMode) {

          // document.documentElement.classList.add('dark-mode');
          document.documentElement.setAttribute('data-bs-theme', 'dark');
          localStorage.setItem('theme', 'dark');
        } else {
          // document.documentElement.classList.remove('dark-mode');
          document.documentElement.setAttribute('data-bs-theme', 'light');
          localStorage.setItem('theme', 'light');
        }
      },
  },
  watch: {
    isDarkMode(newValue) {
      this.themeLabel = newValue ? "Dark" : "Light";
    }
  },
  created() {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      this.isDarkMode = true;
      // document.documentElement.classList.add('dark-mode');
    }else{
      this.isDarkMode = false;
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
