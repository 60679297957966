<template>
  <aside data-bs-theme="dark">
    <div id="docsNav" class="offcanvas-lg offcanvas-start d-flex flex-column position-fixed top-0 start-0 vh-100 bg-dark border-end-lg" style="width: 21rem; z-index: 1045;">
      <div class="offcanvas-header d-none d-lg-flex justify-content-start">
        <router-link to="/" class="navbar-brand text-dark d-none d-lg-flex py-0">
          <img src="@/assets/img/d1m.png" width="180" alt="d1m" />
        </router-link>
        <!-- <span class="badge bg-success d-none d-lg-inline-block">Docs</span> -->
      </div>
      <div class="offcanvas-header d-block d-lg-none border-bottom">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="d-lg-none mb-0">Menu</h5>
          <button type="button" class="btn-close d-lg-none" data-bs-dismiss="offcanvas" data-bs-target="#docsNav" aria-label="Close"></button>
        </div>
        <div class="list-group list-group-flush mx-n4">
          <router-link v-for="item in navItems" :to="item.link" class="list-group-item list-group-item-action d-flex align-items-center border-0 py-2 px-4" :key="item.label">
            <i :class="item.iconClass"></i>
            {{ item.label }}
          </router-link>
        </div>
      </div>
      <div class="offcanvas-body p-4">
        <h3 class="fs-lg">Contents</h3>
        <div class="list-group list-group-flush mx-n4 pb-2">
          <router-link v-for="content in contents" :to="content.link" class="list-group-item list-group-item-action border-0 py-2 px-4" :class="{ active: content.isActive }" :key="content.label">
            {{ content.label }}
          </router-link>
        </div>
      </div>
      <div class="offcanvas-header border-top">
        <router-link to="/login" class="btn btn-primary w-100">
          <i class="bx bx-user fs-4 lh-1 me-1"></i>Login
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      logoSrc: "../assets/logo.png",
      brandName: "D1m",
      navItems: [
        { label: "Preview", link: "/index", iconClass: "bx bx-desktop fs-lg opacity-80 me-2" },
        { label: "UI Kit (Components)", link: "/components/typography", iconClass: "bx bx-layer fs-lg opacity-80 me-2" }
      ],
      contents: [
        { label: "Home", link: "/"},
        { label: "Cool sites", link: "/bookmarks"},
        { label: "Great Minds", link: "/great-minds"},
        { label: "Quotes", link: "/quotes"},
        { label: "Graphs", link: "/graphs"},
        { label: "About", link: "/about"},
        { label: "Login", link: "/login"},
        { label: "ui_kit", link: "/ui_kit/typography"},
        { label: "thief", link: "/thief"},
        { label: "Slot Machine", link: "/slot-machine"},
      ]
    };
  }
};
</script>

<style scoped>
/* Add necessary styles here */
</style>
