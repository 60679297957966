<template>
    <header class="header navbar navbar-expand-lg bg-light shadow-sm fixed-top">
      <div class="container px-3">
        <router-link to="/" class="navbar-brand pe-3">
          <img src="../assets/img/d1m.png" width="47" alt="Silicon">
        </router-link>
        

        <div id="navbarNav" class="offcanvas offcanvas-end">
            <div class="offcanvas-header border-bottom">
            <h5 class="offcanvas-title">Menu</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li v-for="(menuItem, index) in menuItems" :key="index" class="nav-item dropdown">
                    <router-link :to="menuItem.url" class="nav-link" :class="menuItem.dropdown ? 'dropdown-toggle' : ''">{{ menuItem.title }}</router-link>
                    
                    <div v-if="menuItem.dropdown" class="dropdown-menu p-0">
                        <div class="d-lg-flex">
                            <div v-if="menuItem.image" class="mega-dropdown-column d-flex justify-content-center align-items-center rounded-3 rounded-end-0 px-0"
                                style="margin: -1px; background-color: #f3f6ff;">
                                <img :src="menuItem.image" alt="Landings">
                            </div>
                            <div v-for="(section, sectionIndex) in menuItem.sections" :key="sectionIndex" class="mega-dropdown-column pt-lg-3 pb-lg-4">
                                <ul class="list-unstyled mb-0">
                                <li v-if="section.sectionTitle" class="px-3 mb-2">{{ section.sectionTitle }}</li>
                                <li v-for="(link, linkIndex) in section.links" :key="linkIndex">
                                    <router-link :to="link.url" class="dropdown-item d-flex align-items-center">
                                    {{ link.title }}
                                    <span v-if="link.new" class="badge bg-success ms-2">New</span>
                                    </router-link>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            </div>
        </div>


        <div class="form-check form-switch mode-switch pe-lg-1 ms-lg-auto" data-bs-toggle="mode">
            <input class="form-check-input" type="checkbox" id="theme-mode" :checked="isDarkMode" @change="toggleTheme">
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Dark</label>
        </div>
            <button type="button" class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- <a href="https://themes.getbootstrap.com/product/silicon-business-technology-template-ui-kit/"
                class="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex" target="_blank" rel="noopener">
                <i class="bx bx-cart fs-5 lh-1 me-1"></i>
                &nbsp;Buy now
            </a> -->
      </div>
  </header>
</template>

<script>
export default {
    data() {
        return {
        menuItems: [
            {
                title: 'Home',
                dropdown: false,
                url: '/'
            },
            {
                title: 'Cool Sites',
                dropdown: false,
                url: '/bookmarks'
            },
            {
                title: 'Games',
                dropdown: true,
                sections: [
                    {
                        links:[
                            { title: 'Catch the thief', url: '/thief' },
                            { title: 'Slot Machine', url: '/slot-machine' },
                        ],
                    }
                ],
            },
            // {
            // title: 'Landings',
            // dropdown: true,
            // image: '@/assets/img/landings.jpg',
            // url: '#!',
            // sections: [
                
            //     {
            //     links: [
            //         { title: 'Template Intro Page', url: 'index' },
            //         { title: 'Mobile App Showcase v.1', url: 'landing-mobile-app-showcase-v1' },
            //         { title: 'Mobile App Showcase v.2', url: 'landing-mobile-app-showcase-v2' },
            //         { title: 'Mobile App Showcase v.3', url: 'landing-mobile-app-showcase-v3', new: true },
            //         { title: 'Product Landing', url: 'landing-product' },
            //         { title: 'SaaS v.1', url: 'landing-saas-v1' },
            //         { title: 'SaaS v.2', url: 'landing-saas-v2' },
            //         { title: 'SaaS v.3', url: 'landing-saas-v3' },
            //         { title: 'SaaS v.4', url: 'landing-saas-v4' },
            //         { title: 'SaaS v.5', url: 'landing-saas-v5', new: true }
            //     ]
            //     },
            //     {
            //     links: [
            //         { title: 'Startup', url: 'landing-startup' },
            //         { title: 'Financial Consulting', url: 'landing-financial' },
            //         { title: 'Online Courses', url: 'landing-online-courses' },
            //         { title: 'Medical', url: 'landing-medical' },
            //         { title: 'Software Dev Agency v.1', url: 'landing-software-dev-agency-v1' },
            //         { title: 'Software Dev Agency v.2', url: 'landing-software-dev-agency-v2' },
            //         { title: 'Software Dev Agency v.3', url: 'landing-software-dev-agency-v3' },
            //         { title: 'Conference', url: 'landing-conference' },
            //         { title: 'Digital Agency', url: 'landing-digital-agency' },
            //         { title: 'Blog Homepage', url: 'landing-blog' }
            //     ]
            //     }
            // ]
            // },
            // {
            // title: 'Pages',
            // dropdown: true,
            // sections: [
            //     {
            //     sectionTitle: 'About',
            //     links: [
            //         { title: 'About v.1', url: 'about-v1' },
            //         { title: 'About v.2', url: 'about-v2' },
            //         { title: 'About v.3', url: 'about-v3' }
            //     ]
            //     },
            //     {
            //     sectionTitle: 'Blog',
            //     links: [
            //         { title: 'List View with Sidebar', url: 'blog-list-with-sidebar' },
            //         { title: 'Grid View with Sidebar', url: 'blog-grid-with-sidebar' },
            //         { title: 'List View no Sidebar', url: 'blog-list-no-sidebar' },
            //         { title: 'Grid View no Sidebar', url: 'blog-grid-no-sidebar' },
            //         { title: 'Simple Feed', url: 'blog-simple-feed' },
            //         { title: 'Single Post', url: 'blog-single' },
            //         { title: 'Podcast', url: 'blog-podcast' }
            //     ]
            //     }
            // ]
            // },
            // {
            //     title: 'Account',
            //     dropdown: true,
            //     sections: [
            //         {
            //             links:[
            //                 { title: 'Login', url: '/login' },
            //                 { title: 'Account Details', url: 'account-details' },
            //                 { title: 'Security', url: 'account-security' },
            //                 { title: 'Notifications', url: 'account-notifications' },
            //                 { title: 'Messages', url: 'account-messages' },
            //                 { title: 'Saved Items', url: 'account-saved-items' }
            //             ],
            //         }
            //     ],
            // },
            {
                title: 'UI Kit',
                dropdown: false,
                url: '/ui_kit/typography'
            },
            // {
            //     title: 'Docs',
            //     dropdown: [
            //     { name: 'Getting Started', link: 'docs-getting-started' },
            //     { name: 'API Reference', link: 'docs-api' },
            //     { name: 'FAQ', link: 'docs-faq' },
            //     { name: 'Support', link: 'docs-support' }
            //     ]
            // }
        ]
        };
    },
    methods:{
        toggleTheme() {
            this.isDarkMode = !this.isDarkMode;
            if (this.isDarkMode) {

            // document.documentElement.classList.add('dark-mode');
            document.documentElement.setAttribute('data-bs-theme', 'dark');
            localStorage.setItem('theme', 'dark');
            } else {
            // document.documentElement.classList.remove('dark-mode');
            document.documentElement.setAttribute('data-bs-theme', 'light');
            localStorage.setItem('theme', 'light');
            }
        },
    },
    watch: {
        isDarkMode(newValue) {
        this.themeLabel = newValue ? "Dark" : "Light";
        }
    },
    created() {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
        this.isDarkMode = true;
        // document.documentElement.classList.add('dark-mode');
        }else{
        this.isDarkMode = false;
        }
    }
    }
</script>

<style>

</style>